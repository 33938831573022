<section class="error-bg">
    <div class="errorpage">

        <div class="loader-bg">
            <div class="loader-track">
                <div class="loader-fill"></div>
            </div>
        </div>
        <div class="p-b-10 header sticky bg-primary shadow">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="desktoplogo">
                            <img routerLink="/" class="logo shadow" src="assets/images/brand/logo2.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="auth-main errorpage">
            <div class="auth-wrapper v2">
                <div class="auth-form">
                    <div class="card bg-primary">
                        <div class="card-body shadow" [formGroup]="loginForm">
                            <span class="d-flex justify-content-center mb-2">
                                <img routerlink="/" src="assets/img/logo.png" tabindex="0" class="logo shadow">
                                <h1 class="text-center text-light heading ml-2 text-shadow"> Lynkgrid </h1>
                            </span>
                            <div class="form-group mb-3">
                                <input type="email" formControlName="email" class="form-control" id="floatingInput"
                                    placeholder="Email Address">
                            </div>
                            <div class="form-group mb-2">
                                <input [type]="isPasswordVisible ? 'text' : 'password'" formControlName="password"
                                    class="form-control" id="floatingInput1" placeholder="Password">
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="form-check">
                                    <input class="form-check-input" style="margin-top: 0.280em !important;"
                                        type="checkbox" id="checkbox-1" (change)="togglePasswordVisibility()">
                                    <label class="form-check-label text-light" style="font-size: x-small;"
                                        for="checkbox-1">Show Password</label>
                                </div>
                            </div>
                            <div class="d-grid mt-2">
                                <button type="submit" class="btn btn-light" [disabled]="!loginForm.valid"
                                    (click)="login()">Login</button>
                            </div>
                            <div class="d-flex mt-3 justify-content-between align-items-center">
                                <h5 data-bs-target="#trackingModal" data-bs-toggle="modal" id="container_tracking"
                                    class="f-w-400 mb-0 text-light cursor-pointer"
                                    (click)="trackEvent('container_tracking')">
                                    <i class="ti ti-truck "></i> Container Tracking
                                </h5>
                                <h5 class="f-w-400 mb-0 text-light cursor-pointer" data-pc-animate="super-scaled"
                                    data-bs-toggle="modal" data-bs-target="#forgetModal">
                                    <i class="ti ti-lock-off "></i> Forgot Password?
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- anim-super-scaled -->
    <div class="modal fade modal-animate anim-fade-in-scale" id="forgetModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <div class="mb-0">
                        <div class="card-body px-lg-5 pt-lg-4 pb-lg-3">
                            <div class="text-center h2 mb-1"> Forgot Your Password ? </div>
                            @if(isGetOtp){
                            <p class="text-md text-muted text-center">Input OTP Sent to your registered email/mobile to reset
                                your password
                            </p>
                            }@else{

                            <p class="text-md text-muted text-center">Input your registered email/mobile to reset your
                                password
                            </p>
                            }
                            <form class="needs-validation" [formGroup]="getEmail" novalidate>
                                <div class="form-row">
                                    <div class="form-group col-md-12 mb-0" *ngIf="!isGetOtp">
                                        <input placeholder="Please Enter Email / Mobile" formControlName="email"
                                            type="email" class="form-control" id="emal" required>
                                        <span class="error-msg"
                                            *ngIf="(getEmail.get('email')?.touched || getEmail.get('email')?.dirty) && !getEmail.get('email').valid">
                                            <span *ngIf="getEmail.get('email')?.errors?.['required']">
                                                required.
                                            </span>
                                            <!-- <span *ngIf="getEmail.get('email')?.errors?.['pattern']">
                                                Please provide the Valid email.
                                            </span> -->
                                        </span>
                                    </div>

                                    <div class="form-group col-md-12 mb-0 text-center">
                                        <button *ngIf="!isGetOtp" type="button"
                                            [disabled]="!getEmail.get('email').valid" (click)="getOTP()"
                                            class="btn btn-success btn-sm m-1 mt-2">
                                            <i class="fe-check-circle mr-1"></i> Get OTP
                                        </button>
                                        <!-- <button *ngIf="isGetOtp && !isOtpConfirm"  type="button" [disabled]="!getEmail.get('otp').valid" (click)="confirmOTP()"
                                            class="btn btn-success waves-effect waves-light m-1 mt-2">
                                            <i class="fe-check-circle mr-1"></i> Confirm OTP
                                        </button> -->
                                    </div>
                                </div>
                            </form>
                            <form class="needs-validation" *ngIf="isGetOtp" [formGroup]="forgetPw" novalidate>
                                <div class="form-row">
                                    <div class="form-group col-md-12 mb-0">
                                        <label for="emal" class="col-form-label">OTP</label>
                                        <input (keypress)="restrictNumeric($event)" formControlName="otp" type="text"
                                            class="form-control" id="emal" required>
                                        <span class="error-msg"
                                            *ngIf="forgetPw.get('otp').dirty && forgetPw.get('otp').errors">
                                            <span *ngIf="forgetPw.get('otp')?.errors?.['required']">
                                                OTP is required.
                                            </span>
                                        </span>
                                    </div>
                                    <div class="form-group col-md-12 mb-0">
                                        <label for="newPassword" class="col-form-label">New Password</label>
                                        <input formControlName="newPassword" type="password" class="form-control"
                                            id="newPassword">
                                        <small class="error-msg"
                                            *ngIf="forgetPw.get('newPassword').dirty && forgetPw.get('newPassword').errors">
                                            <small *ngIf="forgetPw.get('newPassword').errors?.['required']">
                                                Password is required.
                                            </small>
                                            <small *ngIf="forgetPw.get('newPassword').errors?.['pattern']">
                                                Please Provide Valid Password e.g(Abc&#64;1234)
                                            </small>
                                        </small>
                                    </div>
                                    <!-- <div class="form-group col-md-12 mb-0">
                                        <label for="newPassword" class="col-form-label">Confirm Password</label>
                                        <input formControlName="confrmPassword" (input)="checkPassword($event)"
                                            type="password" class="form-control" id="newPassword"> 
                                        <small class="error-msg"
                                            *ngIf="forgetPw.get('confrmPassword').dirty && forgetPw.get('confrmPassword')errors">
                                            <small *ngIf="forgetPw.get('confrmPassword').errors?.required">
                                                Please Provide Confirm Password.
                                            </small>
                                            <small *ngIf="!isPwMatched">
                                                Password not matched.Please provide correct password
                                            </small>
                                        </small>
                                    </div> -->
                                    <div>
                                        <small>
                                            <code>
                                                Password must contain 1 uppercase, 1 lowercase, 1 special case & 1 numeric character & of minimum 8 characters e.g- Ab&#64;12345
                                            </code>
                                        </small>
                                    </div>
                                </div>
                                <div class="form-group col-md-12 text-center mb-0">
                                    <!-- data-dismiss="modal"  -->
                                    <button type="button" [disabled]="(!forgetPw.valid)" (click)="forgetPassword()"
                                        class="btn btn-success btn-sm m-1 mt-2">
                                        <i class="fe-check-circle mr-1"></i> Reset Password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer py-lg-2">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary shadow-2" [disabled]="!emailControl.valid"
                        (click)="forgetPassword()">Reset Password</button>
                </div> -->
            </div>
        </div>
    </div>

    <div class="modal fade modal-animate anim-fade-in-scale" id="trackingModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <div class="mb-0">
                        <div class="card-body pt-4 px-4">
                            <div class="text-center h2 mb-1"> Track Container ? </div>

                            <form [hidden]="containersData?.length != 0" [formGroup]="trackingForm">

                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0"
                                    formArrayName="baseForm" *ngFor="let item of baseForm['controls']; let i = index;">
                                    <div [formGroupName]="i">
                                        <div class="form-group d-flex">
                                            <div class="form-search my-1">
                                                <i class="ti ti-truck"></i>
                                                <input formControlName="container" name="containerNo" max="11"
                                                    maxlength="11" placeholder="Container No" type="containerNo"
                                                    class="form-control">
                                            </div>
                                            <div class="d-flex justify-content-between ms-1 align-items-center">
                                                <button (click)="addItem()" class="btn btn-primary avtar-xs"
                                                    *ngIf="i === baseForm.length - 1">
                                                    <i class="ti ti-plus"></i>
                                                </button>
                                                <button (click)="deleteRow(i)" class="btn btn-secondary avtar-xs mx-1"
                                                    *ngIf="baseForm.length > 1">
                                                    <i class="ti ti-minus"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group input-group-alternative">
                                        <div class="form-search my-1">
                                            <i class="ti ti-box"></i>
                                            <input formControlName="cfs" name="cfs" class="form-control"
                                                placeholder="ICD/CFS" type="cfs">
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <form *ngIf="containersData?.length != 0">
                                <div class="row">
                                    <table
                                        class="table table-bordered border-top table-hover mb-0 text-nowrap table-responsive">
                                        <thead>
                                            <th>#</th>
                                            <th>Container</th>
                                            <th>Status </th>
                                        </thead>
                                        <tbody *ngFor="let item of containersData; let i=index;">
                                            <td>{{i+1}}</td>
                                            <td>{{item.containerDetails.container}}</td>
                                            <td>
                                                <div *ngIf="!item.tid">
                                                    Gate In Pending
                                                </div>
                                                <ul *ngIf="item.tid" class="list-group list-group-flush">
                                                    <li class="list-group-item"
                                                        *ngIf="item.tid && item.lastStatus == 'gatein' ">
                                                        <h6>Gate In </h6>
                                                    </li>
                                                    <li class="list-group-item"
                                                        *ngIf="!item.tidRetrieved && item.lastStatus != 'gatein' && item.moves.length > 0 && item.moves[0].positionDetails.time.length > 0">
                                                        <div class="cbp_tmlabel empty">
                                                            <h6>Gate In - {{item.loc}}</h6>
                                                            <span
                                                                *ngIf="item?.containerDetails?.actionType !='anotherYard'">
                                                                <button class="btn btn-sm btn-outline-danger"
                                                                    (click)="goToMap(item._id)"> <i
                                                                        class="ti ti-map-2"></i></button>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item"
                                                        *ngIf="item.tidRetrieved && item.lastStatus == 'loading'">
                                                        <div class="cbp_tmlabel empty">
                                                            <span class="font-weight-bold">Gate Out</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="modal-footer py-lg-2">
                    <button (click)="resetContainer()" type="button" data-bs-dismiss="modal"
                        class="btn btn-outline-secondary">Close</button>

                    @if (containersData?.length == 0)
                    {
                    <button type="button" [disabled]="!trackingForm?.valid" (click)="trackContainer()"
                        class="btn btn-primary shadow-2">Track</button>
                    }
                    @else
                    {
                    <button *ngIf="containersData?.length != 0 && viewAllMap == true" type="button" (click)="multiMap()"
                        class="btn btn-outline-primary">View on Map</button>
                    <button type="button" class="btn btn-primary shadow-2" (click)="resetContainer()">Track
                        Another</button>
                    }
                </div>
            </div>
        </div>
    </div>
</section>