<nav class="pc-sidebar">
    <div class="navbar-wrapper">
        <div class="navbar-content">
            <ul class="pc-navbar1">
                <li class="pc-item" id="dashboard" (click)="makeActive('dashboard')">
                    <a routerLink="/dashboard" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-row-vertical"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Dashboard</span>
                    </a>
                </li>
                <li class="pc-item" id="mapPage" (click)="makeActive('map')" *ngIf="token?.permission?.mapView">
                    <a routerLink="/map" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-box-1"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Map</span>
                    </a>
                </li>
                <li class="pc-item" id="analytics" (click)="makeActive('analytics')"
                    *ngIf="token?.permission?.analytics">
                    <a routerLink="/analytics" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-presentation-chart"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Analytics</span>
                    </a>
                </li>
                <li class="pc-item" id="user" (click)="makeActive('user')"
                    *ngIf="
                token?.permission?.createAdmin || token?.permission?.editAdmin || token?.permission?.viewAdmin 
                || token?.permission?.createSubAdmin || token?.permission?.viewSubAdmin || token?.permission?.editSubAdmin
                || token?.permission?.createUsersYard || token?.permission?.viewUsersYard || token?.permission?.editUsersYard 
                || token?.permission?.createUsersCrane || token?.permission?.viewUsersCrane || token?.permission?.editUsersCrane 
                || token?.permission?.createUsersGate || token?.permission?.viewUsersGate || token?.permission?.editUsersGate">
                    <a routerLink="/user" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-user"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Users</span>
                    </a>
                </li>
                <li class="pc-item" id="container" (click)="makeActive('container')"
                    *ngIf="token?.permission?.viewContainers">
                    <a routerLink="/container" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-layer"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Containers</span>
                    </a>
                </li>
                <li class="pc-item" id="gates" (click)="makeActive('gates')"
                    *ngIf="token?.permission?.createGates || token?.permission?.editGates|| token?.permission?.viewGates">
                    <a routerLink="/gates" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-login"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Gates</span>
                    </a>
                </li>
                <!-- (click)="makeActive('miscellaneous')" -->

                <li class="pc-item" id="lane" (click)="makeActive('lane')" *ngIf="token?.permission?.laneAllocation">
                    <a routerLink="/lane" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-kanban"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Lanes</span>
                    </a>
                </li>

                <li class="pc-item" id="asset" (click)="makeActive('asset')"
                    *ngIf="token?.permission?.viewAsset || token?.permission?.addAsset">
                    <a routerLink="/asset" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-shopping-bag"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Assets</span>
                    </a>
                </li>
                <li class="pc-item" id="equipment" (click)="makeActive('equipment')"
                    *ngIf="token?.permission?.viewEquipment">
                    <a routerLink="/equipment" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-cpu-charge"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Equipments</span>
                    </a>
                </li>
                <li class="pc-item" id="reports" (click)="makeActive('reports')" *ngIf="token?.permission?.reports">
                    <a routerLink="/reports" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-document-filter"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Reports</span>
                    </a>
                </li>
                <li class="pc-item" id="taginventory" (click)="makeActive('taginventory')"
                    *ngIf="token?.permission?.tagInventory">
                    <a routerLink="/taginventory" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-password-check"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Tag Inventory</span>
                    </a>
                </li>
                <li class="pc-item" id="jobs" (click)="makeActive('jobs')" *ngIf="token?.permission?.jobs">
                    <a routerLink="/jobs" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-notification-status"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Jobs</span>
                    </a>
                </li>
                <li class="pc-item" id="rakeops" (click)="makeActive('rakeops')" *ngIf="token?.permission?.rakeOps">
                    <a routerLink="/rakeops" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-data"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Rake Ops

                        </span>
                    </a>
                </li>
                <li class="pc-item pc-hasmenu" id="miscellaneous" *ngIf="token?.permission?.download">
                    <a href="javascript:void(0)" class="pc-link">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-document-text"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Miscellaneous</span>
                        <span class="pc-arrow">
                            <i class="ti ti-chevron-right"></i>
                        </span>
                    </a>
                    <ul class="pc-submenu">
                        <li class="pc-item"><a class="pc-link" href="javascript:void(0)"
                                routerLink="/monitoring">Monitoring</a></li>
                        <li class="pc-item"><a class="pc-link" href="javascript:void(0)"
                                routerLink="/miscellaneous">Analysis</a></li>
                    </ul>
                </li>
                <li class="pc-item" id="resources" (click)="makeActive('resources')" *ngIf="!token?.permission?.resources">
                    <a routerLink="/resources" class="pc-link" href="javascript:void(0)">
                        <span class="pc-micon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-data"></use>
                            </svg>
                        </span>
                        <span class="pc-mtext">Resources</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>