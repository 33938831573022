import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
// import { AuthService } from 'src/services';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services';
import { AnalyticsService } from 'src/services/analytics.service';
var containers = [];
declare var Swal, $;

@Component({
  selector: 'app-auth-layout',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './auth-layout.component.html',
  styleUrl: './auth-layout.component.scss'
})
export class AuthLayoutComponent {
  loginForm!: FormGroup;
  forgetPw!: FormGroup;
  getEmail: FormGroup;
  trackingForm: FormGroup;
  emailControl = new FormControl('', [Validators.required, Validators.pattern("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")]);
  password = '';
  token;
  user;
  containersData = [];
  viewAllMap = false;
  isGetOtp: boolean = false;
  isPasswordVisible = false;
  // private service: AuthService
  constructor(private analyticsService: AnalyticsService, private formBuilder: FormBuilder, private router: Router, private service: AuthService) {
    this.checkLogin();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group(
      {
        email: ['', Validators.required],
        password: ['', Validators.required],
      });

    this.forgetPw = new FormGroup(
      {
        otp: new FormControl('', [Validators.required]),
        newPassword: new FormControl("", [Validators.required,
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}$)/)]),
        // confrmPassword: new FormControl("", [Validators.required,]),
      });

    this.getEmail = new FormGroup({
      // Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      email: new FormControl('', [Validators.required]),
    })

    this.createTrackingForm();
  }

  createTrackingForm() {
    this.trackingForm = this.formBuilder.group(
      {
        baseForm: this.formBuilder.array([this.createItem()]),
        cfs: ['', Validators.required],
      });
  }

  createItem(): FormGroup {
    return this.formBuilder.group(
      {
        container: ['', Validators.compose([Validators.required, Validators.pattern(/^([a-zA-Z]{4})([0-9]{7})$/)])],
      });
  }

  get baseForm(): FormArray {
    return this.trackingForm.get('baseForm') as FormArray;
  };

  addItem(): void {
    this.baseForm.push(this.createItem());
  }

  deleteRow(index: number) {
    const control = <FormArray>this.trackingForm.controls['baseForm'];
    control.removeAt(index);
  }

  getEmailErrorMessage() {
    if (this.emailControl.hasError('required')) {
      return 'You must enter a value';
    }

    return this.emailControl.hasError('pattern') ? 'Not a valid email' : '';
  }

  login() {
    this.analyticsService.trackEvent('click', 'user_interaction', 'login_click')
    const { password, email } = this.loginForm.value;
    this.service.login({ password, email, type: 'app' }).subscribe((res: any) => {
      const { success, token, data, message, sso, sessionKey } = res
      if (success) {
        localStorage.setItem(environment.auth_token, token);
        localStorage.setItem('user', JSON.stringify(data));
        if (sso) {
          localStorage.setItem(environment.sso_token, sso.token);
          localStorage.setItem('sso_details', JSON.stringify(sso));
        }

        if (sessionKey) {
          localStorage.setItem('sessionKey', sessionKey);
        }

        this.user = data;
        if (localStorage.getItem('user')) {
          this.route()
        }

        // if (localStorage.getItem(environment.auth_token)) {
        //   this.service.getUserData({}).subscribe((res: any) => {
        //     if (res.success) {
        this.token = res.data;
        if (this.token.theme) {
          localStorage.setItem('theme', this.token.theme)
        }
        // this.yardArr = res.data.yards;
        // this.filterDefaultYard();

        // var stoken = JSON.parse(atob((localStorage.getItem(environment.auth_token)).split('.')[1]));
        // var tokn = stoken;
        // tokn["iat"] = stoken.iat;
        // this.socket.connectSocket(stoken);
        // this.socket.getSocketID()

        window.location.reload();
        //     }
        //     else {
        //       this.service.showToast("Error", "Session Expired! Please login again")
        //       return;
        //     }
        //   }, this.service.error)
        // }
      }
      else {
        this.service.showToast('error', message)
      }
    }, this.service.error)
  }

  trackEvent(id) {
    this.analyticsService.trackEvent('click', 'user_interaction', id)
  }

  private route() {
    this.user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

    if (this.user?.homePage) {
      this.router.navigate([`/${this.user.homePage}`]).then(() => { });
    }
    else {
      this.router.navigate(['dashboard']).then(() => { });
    }
  }

  getOTP() {
    this.service.forgetPassword({ email: this.getEmail.value.email }).subscribe((res: any) => {
      const { success, data, message } = res
      if (success) {
        this.isGetOtp = true;
        this.service.showToast("success", message)
      }
      else {
        this.service.showToast("error", message)
      }
    }, this.service.error)
  }

  forgetPassword() {
    let obj = {
      "otp": this.forgetPw.value.otp,
      "password": this.forgetPw.value.newPassword,
      "email": this.getEmail.value.email,
    }
    // console.log("resetPassword object", obj);
    this.service.setNewPasswordSSO(obj).subscribe((res: any) => {
      if (res.success) {
        $("#forgetModal").modal('hide');
        this.isGetOtp = false;
        this.service.showToast(res.message, "success")
        this.getEmail.reset();
        return true;
      } else {
        this.service.showToast(res.message, "error")
        return false;
      }
    })
  }

  checkLogin() {
    this.loginForm = this.formBuilder.group(
      {
        email: ['', Validators.required],
        password: ['', Validators.required],
      });

    if (localStorage.getItem(environment.auth_token) && localStorage.getItem('user')) {
      this.route();
    }
  }

  multiMap() {
    localStorage.setItem('multi', JSON.stringify(containers));
    window.open(window.location.origin + '/trackmap?id=multi&cfs=' + this.trackingForm.value.cfs, '_blank');
  }

  trackContainer() {
    this.viewAllMap = false;
    containers = [];
    this.trackingForm.value.baseForm.map((item) => {
      containers.push(item.container.toUpperCase());
    })

    this.containersData = [];
    let obj = { "containerNo": ["EMCU3906207"], "cfs": "THR", "selectedYard": null, "yardID": null }
    this.service.trackContainer({ containerNo: containers, cfs: this.trackingForm.value.cfs }).subscribe((res: any) => {
      const { success, message, data } = res;
      if (success) {
        if (data.length == 1 && data[0].containerDetails.actionType == 'anotherYard') {
          this.service.showToast('error', 'Container Shifted to Other Yard');
          return;
        }

        var shiftedConts = [];

        data.map((item) => {
          if (item.containerDetails.actionType != 'anotherYard') {
            if (item.lastStatus == 'picked') {
              this.viewAllMap = true;
              item.loc = item.moves[0].positionDetails.time[item.movesCount].picked.properties.name
              if (item.moves[0].positionDetails.time[item.movesCount].picked.properties.newName) {
                item.loc = item.moves[0].positionDetails.time[item.movesCount].picked.properties.newName
              }
              item.time = item.moves[0].positionDetails.time[item.movesCount].start;
            }
            else if (item.lastStatus == 'stacked') {
              this.viewAllMap = true;
              item.loc = item.moves[0].positionDetails.time[item.movesCount].stacked.properties.name;
              if (item.moves[0].positionDetails.time[item.movesCount].stacked.properties.newName) {
                item.loc = item.moves[0].positionDetails.time[item.movesCount].stacked.properties.newName
              }
              item.time = item.moves[0].positionDetails.time[item.movesCount].end;
            }
            this.containersData.push(item);
          }
          else {
            shiftedConts.push(item.containerDetails.container)
          }
        })

        if (shiftedConts.length != 0) {
          this.service.showToast('error', shiftedConts.toString() + " Container Shifted to Other Yard");
        }

      }
      else {
        this.service.showToast('error', message)
      }
    }, this.service.error)
  }

  containerNo;
  cfs;
  goToMap(id) {

    var data = this.containersData.filter(item => { return item._id == id });

    localStorage.setItem(id, JSON.stringify(data[0]));

    window.open(window.location.origin + '/trackmap?id=' + id + '&cfs=' + this.trackingForm.value.cfs, '_blank');
  }

  resetContainer() {
    this.containersData = [];
    this.containerNo = "";
    this.cfs = "";
  }

  public restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which === 46) {
      return false;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\.\s]/.test(input);
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

}
