import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { VersionCheckService } from '../services/version-check.service';
import { ConnectionService } from 'ng-connection-service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/services';
import { AnalyticsService } from 'src/services/analytics.service';
import { Gtag } from 'angular-gtag';
import { filter, map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SocketService } from 'src/services/socket.service';
declare var $, gtag;
var $messages, i = 0;
var AIResponse: any = []
var myque: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'Lynkgrid';
  isConnected: any = true;
  static service: AuthService;
  webSocket: WebSocket;
  private routerSubscription!: Subscription;
  // private gtag: Gtag,
  token: any
  constructor(private versionCheckService: VersionCheckService, private router: Router, private connectionService: ConnectionService, private titleService: Title, private socket: SocketService,
    private service: AuthService,) {
    // this.connectionService.monitor().subscribe(isConnected=>
    // {
    //   this.isConnected = isConnected;
    //   if (isConnected)
    //   {
    //     this.service.showToast('Success','Internet connection is back' )
    //   }
    //   else
    //   {
    //     this.service.showToast('error', 'Internet connection is lost' )
    //   }
    // })
  }

  isLoggedIn: any

  ngOnInit() {
    
    this.routerSubscription = this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        // gtag('config', 'WKR25Z6JKN', {  // for live
        // 	page_path: event.urlAfterRedirects,
        // 	page_title: this.getRouteSnapshot().data.title
        // }); 
        setTimeout(() => {

          var sidebar_hide = document.querySelector('#sidebar-hide');

          if (sidebar_hide) {
            sidebar_hide.addEventListener('click', () => {
              hideSideBarDesktop();
            });
          }

          var mobile_collapse_over = document.querySelector('#mobile-collapse');
          if (mobile_collapse_over) {
            mobile_collapse_over.addEventListener('click', function () {
              hideSideBarMobile();
            });
          }

          // const title = this.getRouteTitle(this.router.routerState.snapshot.root);

          // const pageTitle = this.titleService.getTitle();

          // if (this.gtag) 
          // {
          //   this.gtag.pageview({
          //     page_path: event.urlAfterRedirects,
          //     page_title: title ? title : pageTitle,
          //     debug_mode: !environment.production
          //   });

          //   this.gtag.event('page_view', {
          //     page_path: event.urlAfterRedirects,
          //     page_title: this.getRouteTitle(this.router.routerState.snapshot.root)
          //   });
          //   console.log('title ::', title, 'pageTitle', pageTitle);
          // }
          // else 
          // {
          //   console.error('gtag is not defined');
          // }

          // gtag('config', 'PM8BKCMP8T', {  // for Test
          // 	page_path: event.urlAfterRedirects,
          // 	page_title: this.getRouteSnapshot().data.title
          // });
        }, 500)
      });

    this.versionCheckService.initVersionCheck(
      environment.versionCheckURL,
      1000 * 60 * 30
    );


    if (localStorage.getItem(environment.auth_token)) {
      this.service.getUserData({}).subscribe((res: any) => {
        if (res.success) {
          this.token = res.data;
          console.log('token', this.token);

        }
      }, this.service.error)
    }
    else {
      return;
      // router.navigate(["/"])
    }

    $messages = $('.chat-box-overlay')
    this.chatGpt();
    // this.chatQuery()

    $(window).on('load', function () {
      $messages.mCustomScrollbar();
      if (!localStorage.getItem('chat')) {
        console.log('initialize chat');

        setTimeout(function () {
          AI_rpl('Hello! I\'m L.U.C.A, How can I assist you today?');
        }, 100);
      } else {
        AIResponse = JSON.parse(localStorage.getItem('chat'))

        if (AIResponse && AIResponse.length > 1) {
          AIResponse.forEach((e, i) => {
            if (e.msg != "") {
              if (e.me) {
                AI_rpl('', e.msg)
              } else {
                insertMessage(e.msg)
              }
            }
          })
        }
      }
    });
  }

  isLoggedInCheck()
  {
    return localStorage.getItem('sessionKey');
  }

  getRouteTitle(route: any): string {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.data && route.data['title'] && route.data?.['title'] || '';
  }

  getRouteSnapshot() {
    let route = this.router.routerState.snapshot.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  openChat() {
    // var tokn = JSON.parse(atob(localStorage.getItem(environment.auth_token).split('.')[1])).token;

    this.webSocket = this.socket.connect('open', this.token);

    if (this.webSocket) {
      this.webSocket.onopen = () => {
        console.log('WebSocket connection established.');
      };

      this.webSocket.onmessage = (event) => {
        // console.log('Received message:', event.data);
        AI_rpl(event.data);
      };

      this.webSocket.onclose = (event) => {
        console.log('WebSocket connection closed:', event);
      };

      this.webSocket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    }

  }

  chatGpt() {

    $("#chat-circle").click(function () {
      $("#chat-circle").toggle('scale');
      $(".chat-box").toggle('scale');
    })

    $(".chat-box-toggle").click(function () {
      $("#chat-circle").toggle('scale');
      $(".chat-box").toggle('scale');
    })

  }

  clearChat() {
    if (document.getElementById('mCSB_1_container')) {
      document.getElementById('mCSB_1_container').innerHTML = '';
    }
    if (localStorage.getItem('chat')) {
      AIResponse = [];
      localStorage.removeItem('chat');
      AI_rpl('Hello! I\'m L.U.C.A, How can I assist you today?');
    }
    this.socket.closeConnection();
  }

  insertInput(e) {
    if (e.which == 13) {
      var msg = $('.chat-input').val();
      this.socket.sendMessage(msg);
      insertMessage();
      return false;
    } else {
      return true
    }
  }


  chatQuery() {
    // $('.chat-submit').click(function () {
    var msg = $('.chat-input').val();
    this.socket.sendMessage(msg);
    insertMessage();
    // }); 
  }

  closeChat() {
    if (AIResponse.length != 0) {
      localStorage.setItem('chat', JSON.stringify(AIResponse));
    }
    this.socket.closeConnection();
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}

function AI_rpl(msg, msgg?) {
  if ($('.chat-input').val() != '') {
    return false;
  }
  if (!msgg) {
    AIResponse.push({ msg, me: true });
    $('<div class="message loading new"><span></span></div>').appendTo($('.mCSB_container'));
    updateScrollbar();

    setTimeout(function () {
      $('.message.loading').remove();
      $('<div class="message new">' + msg + '</div>').appendTo($('.mCSB_container')).addClass('new');
      // setDate();
      if (msg !== "Hello! I'm L.U.C.A, How can I assist you today?") {
        $('<div class="message new"><p>This information is relevant to you.?</p><span class="d-flex justify-content-center"><button class="btn btn-sm btn-outline-primary me-1" id="yesResponse">Yes</button><button class="btn btn-sm btn-outline-primary" id="noResponse">No</button></span></div>').appendTo($('.mCSB_container')).addClass('new')

        updateConmfirmation(msg)
      }
      updateScrollbar();
      i++;
    }, 1000 + (Math.random() * 20) * 100);

    return true;

  } else {
    msg = msgg;
    $('<div class="message loading new"><span></span></div>').appendTo($('.mCSB_container'));
    updateScrollbar();
    $('.message.loading').remove();
    $('<div class="message new"><p>' + msg + '</p></div>').appendTo($('.mCSB_container')).addClass('new');
    // if (!confirmation) {
    // } else {
    // 	$('<div class="message new"><p>This information is revelent to you.?</p><span class="d-flex justify-content-center"><button class="btn btn-sm btn-outline-primary">Yes</button><button class="btn btn-sm btn-outline-primary">No</button></span></div>').appendTo($('.mCSB_container')).removeClass('new')
    // } 
    // setDate();
    updateScrollbar();
    i++;
    return true
  }


}

function updateConmfirmation(msg?) {
  document.getElementById('noResponse').addEventListener('click', function (event) {
    event.preventDefault();
    event.stopPropagation();
    // AppComponent.service.chatAPI({
    //   "question": myque,
    //   "answer": msg,
    //   "is_correct": 'no',
    // }).subscribe((res: any) => {
    //   let { success, message, error } = res;
    //   if (success) {
    //     console.log(message);
    //   } else {
    //     console.log(message);
    //   }
    // })
    $(this).closest('.message').remove();
  })
  document.getElementById('yesResponse').addEventListener('click', function (event) {
    // console.log('eeee', event);
    event.preventDefault();
    event.stopPropagation();
    // AppComponent.service.chatAPI({
    //   "question": myque,
    //   "answer": msg,
    //   "is_correct": 'yes',
    // }).subscribe((res: any) => {
    //   let { success, message, error } = res;
    //   if (success) {
    //     console.log(message);
    //   } else {
    //     console.log(message);
    //   }
    // })
    $(this).closest('.message').remove();
  })
}

function updateScrollbar() {
  $messages.mCustomScrollbar("update").mCustomScrollbar('scrollTo', 'bottom', {
    scrollInertia: 10,
    timeout: 0
  });
}


function insertMessage(msgg?) {
  if (msgg) {
    $('.chat-input').val(msgg);
    var msg = $('.chat-input').val();
  } else {
    var msg = $('.chat-input').val();
    AIResponse.push({ msg, you: true });
  }

  console.log('newwww', AIResponse);
  if ($.trim(msg) == '') {
    return false;
  }
  $('<div class="message message-personal">' + msg + '</div>').appendTo($('.mCSB_container')).addClass('new');
  // setDate();  
  myque = $('.chat-input').val();
  console.log('myque', myque);

  $('.chat-input').val(null);
  updateScrollbar();
  // setTimeout(function () {
  // 	AI_rpl('nn');
  // }, 1000 + (Math.random() * 20) * 100);
  return true;
}


function hideSideBarMobile() {
  var temp_sidebar = document.querySelector('.pc-sidebar');
  if (temp_sidebar) {
    if (document.querySelector('.pc-sidebar').classList.contains('mob-sidebar-active')) {
      rm_menu();
    }
    else {
      document.querySelector('.pc-sidebar').classList.add('mob-sidebar-active');
      document.querySelector('.pc-sidebar').insertAdjacentHTML('beforeend', '<div class="pc-menu-overlay"></div>');

      if (document.querySelector('.pc-menu-overlay')) {
        document.querySelector('.pc-menu-overlay').addEventListener('click', function () {
          rm_menu();

          if (document.querySelector('.hamburger')) {
            document.querySelector('.hamburger').classList.remove('is-active');
          }
        });
      }
    }
  }
}

function rm_menu() {
  var temp_list = document.querySelector('.pc-sidebar');
  if (temp_list) {
    document.querySelector('.pc-sidebar').classList.remove('mob-sidebar-active');
  }

  if (document.querySelector('.topbar')) {
    document.querySelector('.topbar').classList.remove('mob-sidebar-active');
  }

  if (document.querySelector('.pc-sidebar .pc-menu-overlay')) {
    document.querySelector('.pc-sidebar .pc-menu-overlay').remove();
  }

  if (document.querySelector('.topbar .pc-menu-overlay')) {
    document.querySelector('.topbar .pc-menu-overlay').remove();
  }
}

function hideSideBarDesktop() {
  if (document.querySelector('.pc-sidebar')?.classList.contains('pc-sidebar-hide')) {
    document.querySelector('.pc-sidebar')?.classList.remove('pc-sidebar-hide');
    document.querySelector('.pc-header')?.classList.remove('pc-header-hide');
    document.querySelector('.pc-container')?.classList.remove('pc-container-hide');
  }

  else {
    document.querySelector('.pc-sidebar')?.classList.add('pc-sidebar-hide');
    document.querySelector('.pc-header')?.classList.add('pc-header-hide');
    document.querySelector('.pc-container')?.classList.add('pc-container-hide');
  }
}

