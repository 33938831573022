<router-outlet></router-outlet>

<div [hidden]="!isLoggedInCheck()">
    <!-- [hidden]="!loggedIn() || !token?.permission?.chatAI" -->
    <img src="assets/img/luca1.png" alt="" id="chat-circle" (click)="openChat()"
    [hidden]="!token?.permission?.chatAI">
    
    <!-- [hidden]="!loggedIn() || !permission?.chatAI" -->
    <div class="chat-box" [hidden]="!token?.permission?.chatAI">
        <div class="chat-box-header align-items-center py-2">
            <img src="assets/img/luca1.png" width="35px" class="mx-2" />
            <div>
                <span class="chat-box-toggle text-secondary" (click)="clearChat()"><i class="ti ti-x"></i></span>
                <span class="chat-box-toggle text-secondary" (click)="closeChat()"><i class="ti ti-minus"></i></span>
            </div>
        </div>
        <div class="chat-box-body messages">
            <div class="chat-box-overlay messages-content"> </div>
        </div>
        <div>
            <form>
                <input type="text" class="chat-input" id="chat-input" placeholder="Send a message..." />
                <button type="submit" class="chat-submit" (click)="chatQuery()" id="chat-submit"><i
                        class="material-icons">send</i></button>
            </form>
        </div>
    </div>
</div>