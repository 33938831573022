import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService, StorageService } from 'src/services';
declare var SimpleBar;
@Component({
  selector: 'app-sidebar-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './sidebar-layout.component.html',
  styleUrl: './sidebar-layout.component.scss'
})
export class SidebarLayoutComponent {
  token;

  private routeSubscription: Subscription;
  constructor(private route: ActivatedRoute, private router: Router, private service: AuthService, @Inject(DOCUMENT) private document: Document, private storage: StorageService) {
    if (localStorage.getItem(environment.auth_token)) {
      this.service.getUserData({}).subscribe((res: any) => {
        if (res.success) {
          this.token = res.data
        }
        else {
          this.service.showToast("Error", "Session Expired! Please login again")
          return;
        }
      }, this.service.error)
    }
    else {
      return;
    }
  }

  ngOnInit() {

     
    if (!!document.querySelector('.navbar-content')) {
      new SimpleBar(document.querySelector('.navbar-content'));
    }

    setTimeout(() => {
      var elem = document.querySelectorAll('.pc-sidebar .pc-navbar1 a');
      for (var l = 0; l < elem.length; l++) {
        var pageUrl = window.location.href.split(/[?#]/)[0];
        var elemVal: any = elem[l];
        // if (elemVal.href == pageUrl && elemVal.getAttribute('href') != '') {
        if (elemVal.href.includes(pageUrl) && elemVal.getAttribute('href') != '') {
          elemVal.parentNode.classList.add('active');
          elemVal.parentNode.parentNode.parentNode.classList.add('pc-trigger');
          elemVal.parentNode.parentNode.parentNode.classList.add('active');
          elemVal.parentNode.parentNode.style.display = 'block';

          elemVal.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add('pc-trigger');
          elemVal.parentNode.parentNode.parentNode.parentNode.style.display = 'block';
        }
      }

      var elem = document.querySelectorAll('.pc-navbar1 li:not(.pc-trigger) .pc-submenu');
      if (elem) {
        for (var j = 0; j < elem.length; j++) {
          (elem[j] as HTMLElement).style.display = 'none';
        }
      }

      var pc_link_click = document.querySelectorAll('.pc-navbar1 > li:not(.pc-caption)');

      for (var i = 0; i < pc_link_click.length; i++) {
        pc_link_click[i].addEventListener('click', function (event) {
          event.stopPropagation();
          var targetElement: any = event.target as HTMLElement;
          if (targetElement.parentNode instanceof HTMLElement) {
            if (targetElement.parentNode.classList.contains('pc-hasmenu')) {
              if (targetElement.parentNode.classList.contains('pc-trigger')) {
                targetElement.parentNode.classList.remove('pc-trigger');
                var elem = document.querySelectorAll('.pc-navbar1 li:not(.pc-trigger) .pc-submenu');
                if (elem.length > 0) {
                  // console.log('pc-hasmenu', targetElement.parentNode.classList);
                  for (var j = 0; j < elem.length; j++) {
                    (elem[j] as HTMLElement).style.display = 'none';
                  }
                }
              } else {
                targetElement.parentNode.classList.add('pc-trigger');
                elem = document.querySelectorAll('.pc-navbar1 li.pc-trigger .pc-submenu');
                if (elem.length > 0) {
                  for (var j = 0; j < elem.length; j++) {
                    (elem[j] as HTMLElement).style.display = 'block';
                  }
                }
              }
            }
          }
        });
      }
    }, 1000)
    // var tc = document.querySelectorAll('li.pc-trigger');

    // for (var t = 0; t < tc.length; t++) 
    // {
    //   var c = tc[t];
    //   c.classList.remove('pc-trigger');
    //   c.classList.remove('active');
    // }
    // targetElement.parentNode.classList.add('pc-trigger');
    // targetElement.parentNode.classList.add('active');
  }

  makeActive(route) {
    this.router.navigate(['/' + route]);
    if (document.getElementById(route)) {
      document.getElementById(route).classList.add("active");
    }
    localStorage.setItem('activeRoute', route)
  }

  logout() {
    // console.log('logout');
    this.router.navigate(['login'])
  }
  ngOnDestroy(): void {

  }
}

