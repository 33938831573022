import { ChangeDetectorRef, Component } from '@angular/core';
import { Router, NavigationEnd, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services';
import { filter } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import * as _ from 'lodash';

declare function layout_change(args);
declare function layout_change_default();
declare var bootstrap;

@Component({
  selector: 'app-header-layout',
  standalone: true,
  imports: [CommonModule,RouterOutlet, RouterLink, RouterLinkActive, FormsModule, ReactiveFormsModule],
  templateUrl: './header-layout.component.html',
  styleUrl: './header-layout.component.scss'
})
export class HeaderLayoutComponent 
{
  loggedIn = true;
  token;
  allNotifications = [];
  selectedYard: string = '';
  depotName;
  allYards = [];

  depotId;
  unreadNotificationCount = 0;
  defaultYard: any;
  yardArr = []
  loader;
  ldbData;
  
  constructor(private cdr: ChangeDetectorRef, private service: AuthService,private router: Router) 
  {
    if (localStorage.getItem(environment.auth_token)) 
    {
      this.service.getUserData({}).subscribe((res: any) => 
      {
        if (res.success) 
        {
          this.token = res.data;

          this.yardArr = this.token.yards
          this.filterDefaultYard();
          if(this.token.yards.length > 0)
          {
            this.selectedYard = this.token.yards.find(yard => yard.defaultYard).id;
          }
        }
        else 
        {
          this.service.showToast("Error", "Session Expired! Please login again")
          return;
        }
      }, this.service.error)
    }
    else 
    {
      return;
    }
  }

  ngOnInit() 
  {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => 
    {
      if (event.url === '/home') 
      {
        // this.getNotifications();
      }
    });
  }

  routePage()
  {
    this.router.navigate(['/setting'])
  }

  layoutChange(theme)
  {
    localStorage.setItem('theme', theme)
    this.service.setDefaultTheme({theme}).subscribe((res: any) => 
    {
      if (res.success) 
      {
        theme == 'default' ? layout_change_default() : layout_change(theme)
      }
      else 
      {

      }
    }, this.service.error)
  }

  filterDefaultYard() 
  {
    try 
    {
      if (this.yardArr && this.yardArr.length != 0) 
      {
        if (this.yardArr.length > 1) 
        {
          this.defaultYard = this.yardArr.filter(x => x.defaultYard == true)[0];
          if (this.defaultYard) 
          {
            localStorage.setItem('selectedYard', this.defaultYard.id);
            localStorage.setItem('yardID', this.defaultYard.yardID);
          }
        } 
        else if (this.yardArr.length == 1) 
        {
          this.defaultYard = this.yardArr[0]
          localStorage.setItem('selectedYard', this.yardArr[0].id);
          localStorage.setItem('yardID', this.yardArr[0].yardID);
        }
      }
    }
    catch (error) {
      console.error('Error fetching yards:', error);
    }
  }

  getYard() {
    return new Promise((resolve, reject) => {
      let obj = { "selectedBase": this.token.depotID[0], "currentPage": 1, "pageSize": "10" };

      this.service.getYard(obj).subscribe((res: any) => {
        const { success, data, count, message } = res;

        if (success) {
          this.allYards = data.reverse();
          resolve(data); // Resolve the promise with the data
        }
        else {
          reject(message); // Reject the promise with the error message
        }
      },
        (error) => {
          reject(error); // Reject the promise with the error
        });
    });
  }

  yardChange(e)
  {
    this.selectedYard = e.target.value;
    this.setDefaultYard(this.selectedYard);
  }

  setDefaultYard(id) 
  {
    this.service.setDefaultYard({ _id: id, selectedBase: this.token.depotID[0] }).subscribe((res: any) => {
      const { data, message, success } = res;
      if (success) 
      {
        localStorage.setItem('selectedYard', id);
        localStorage.setItem('yardID', data.yardID);
        this.service.showToast('success', message);
        window.location.reload();
      }
    })
  }

  trackContainer()
  {
    var containerNo = document.getElementById('trackContainer');  
    if(containerNo)
    {
      // this.loader.start();
      this.ldbData = null;
      this.service.getContainerLocationFromLDB({ container: containerNo["value"], selectedBase: this.token.depotID[0], })
      .subscribe((res: any) => 
      {
        if (res.success) 
        {
          this.ldbData = res.data;
  
          var eximTrail = this.ldbData.eximContainerTrail;
  
          if(eximTrail.trackLog && eximTrail.trackLog.length > 0)
          {
            if(eximTrail)
            {
              if(eximTrail.vessel_eta && eximTrail.vessel_eta.length > 0)
              {
                this.ldbData.eximContainerTrail.trackLog.push(eximTrail.vessel_eta[0])
              }
              if(eximTrail.vessel_etd && eximTrail.vessel_etd.length > 0)
              {
                this.ldbData.eximContainerTrail.trackLog.push(eximTrail.vessel_etd[0])
              }
              if(eximTrail.vessel_ata && eximTrail.vessel_ata.length > 0)
              {
                this.ldbData.eximContainerTrail.trackLog.push(eximTrail.vessel_ata[0])
              }
              if(eximTrail.vessel_atd && eximTrail.vessel_atd.length > 0)
              {
                this.ldbData.eximContainerTrail.trackLog.push(eximTrail.vessel_atd[0])
              }
            }
          }
  
          this.ldbData.eximContainerTrail.trackLog = _.sortBy(this.ldbData.eximContainerTrail.trackLog, o => o.timeinms);
  
          for (let i = 0; i < this.ldbData.eximContainerTrail.trackLog.length - 1; i++) 
          {
  
              const diffMs = this.ldbData.eximContainerTrail.trackLog[i+1].timeinms - this.ldbData.eximContainerTrail.trackLog[i].timeinms;
    
              this.ldbData.eximContainerTrail.trackLog[i+1].difference  = formatTimeDifference(diffMs)
          }
          var myOffcanvas = document.getElementById('offcanvasLDB2')
          var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
          if(bsOffcanvas)
          {
            bsOffcanvas.toggle();
          }
        } 
        else 
        {
          this.service.showToast('error', res.message);
        }
      })
    }
  }

  goToGoogleMaps(latitude, longitude) 
  {
    window.open('https://maps.google.com?q=' + latitude + ',' + longitude, '_blank');
  }

  logout() 
  {
    this.service.logout();
  }
}


function formatTimeDifference(ms) {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const remainingHours = hours % 24;
  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  let result = [];
  if (days > 0) result.push(`${days} d`);
  if (remainingHours > 0) result.push(`${remainingHours} h`);
  if (remainingMinutes > 0) result.push(`${remainingMinutes} m`);
  if (remainingSeconds > 0) result.push(`${remainingSeconds} s`);

  return result.join(" ");
}