import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) 
{
  enableProdMode();
}

// const noop = () => {}

//   ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
//     'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
//     'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
//     'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn',
//   ].forEach((method) => {
//     window.console[method] = noop
//   })

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
