<header class="pc-header ">
    <div class="header-wrapper">
        <div class="me-auto pc-mob-drp">
            <ul class="list-unstyled">
                <!-- ======= Menu collapse Icon ===== -->
                <li class="pc-h-item pc-sidebar-collapse">
                    <a href="javascript:void(0)" class="pc-head-link ms-0" id="sidebar-hide">
                        <i class="ti ti-menu-2"></i>
                    </a>
                </li>
                <li class="pc-h-item pc-sidebar-popup">
                    <a href="javascript:void(0)" class="pc-head-link ms-0" id="mobile-collapse">
                        <i class="ti ti-menu-2"></i>
                    </a>
                </li>
                <li class="pc-h-item">
                    <img src="assets/img/logodark.png" id="logo-web">
                    <!-- <img src="assets/img/logo.png" id="logo-mobile"> -->
                </li>

                <!-- <li class="dropdown pc-h-item">
                    <a class="pc-head-link dropdown-toggle arrow-none m-0 trig-drp-search" data-bs-toggle="dropdown"
                        href="javascript:void(0)" role="button" aria-haspopup="false" aria-expanded="false">
                        <svg class="pc-icon">
                            <use xlink:href="#custom-search-normal-1"></use>
                        </svg>
                    </a>
                    <div class="dropdown-menu pc-h-dropdown drp-search">
                        <form class="px-3 py-2">
                            <input type="search" class="form-control border-0 shadow-none"
                                placeholder="Search here. . ." />
                        </form>
                    </div>
                </li> -->
            </ul>
        </div>

        <div class="ms-auto">
            <ul class="list-unstyled">
                <li class="pc-h-item d-none d-md-inline-flex">
                    <a class="form-search">
                        <i class="search-icon">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-search-normal-1"></use>
                            </svg> </i>
                        <input id="trackContainer" (keyup.enter)="trackContainer()" type="search" class="form-control"
                            placeholder="Track Container via LDB">
                    </a>
                </li>
                <li class="dropdown pc-h-item">
                    <a class="pc-head-link dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown"
                        href="javascript:void(0)" role="button" aria-haspopup="false" aria-expanded="false">
                        <svg class="pc-icon">
                            <use xlink:href="#custom-sun-1"></use>
                        </svg>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end pc-h-dropdown">
                        <a href="javascript:void(0)" class="dropdown-item" (click)="layoutChange('dark')">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-moon"></use>
                            </svg>
                            <span>Dark</span>
                        </a>
                        <a href="javascript:void(0)" class="dropdown-item" (click)="layoutChange('light')">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-sun-1"></use>
                            </svg>
                            <span>Light</span>
                        </a>
                        <a href="javascript:void(0)" class="dropdown-item" (click)="layoutChange('default')">
                            <svg class="pc-icon">
                                <use xlink:href="#custom-setting-2"></use>
                            </svg>
                            <span>Default</span>
                        </a>
                    </div>
                </li>
                <!-- <li class="dropdown pc-h-item">
                    <a class="pc-head-link dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" href="javascript:void(0)"
                        role="button" aria-haspopup="false" aria-expanded="false">
                        <svg class="pc-icon">
                            <use xlink:href="#custom-setting-2"></use>
                        </svg>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end pc-h-dropdown">
                        <a href="javascript:void(0)" class="dropdown-item">
                            <i class="ti ti-user"></i>
                            <span>My Account</span>
                        </a>
                        <a href="javascript:void(0)" class="dropdown-item">
                            <i class="ti ti-settings"></i>
                            <span>Settings</span>
                        </a>
                        <a href="javascript:void(0)" class="dropdown-item">
                            <i class="ti ti-headset"></i>
                            <span>Support</span>
                        </a>
                        <a href="javascript:void(0)" class="dropdown-item">
                            <i class="ti ti-lock"></i>
                            <span>Lock Screen</span>
                        </a>
                        <a href="javascript:void(0)" class="dropdown-item" (click)="logout()">
                            <i class="ti ti-power"></i>
                            <span>Logout</span>
                        </a>
                    </div>
                </li> -->
                <!-- <li class="pc-h-item">
                    <a href="javascript:void(0)" class="pc-head-link me-0" data-bs-toggle="offcanvas" data-bs-target="#announcement"
                        aria-controls="announcement">
                        <svg class="pc-icon">
                            <use xlink:href="#custom-flash"></use>
                        </svg>
                    </a>
                </li>
                <li class="dropdown pc-h-item">
                    <a class="pc-head-link dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" href="javascript:void(0)"
                        role="button" aria-haspopup="false" aria-expanded="false">
                        <svg class="pc-icon">
                            <use xlink:href="#custom-notification"></use>
                        </svg>
                        <span class="badge bg-success pc-h-badge">3</span>
                    </a>
                    <div class="dropdown-menu dropdown-notification dropdown-menu-end pc-h-dropdown">
                        <div class="dropdown-header d-flex align-items-center justify-content-between">
                            <h5 class="m-0">Notifications</h5>
                            <a href="javascript:void(0)" class="btn btn-link btn-sm">Mark all read</a>
                        </div>
                        <div class="dropdown-body text-wrap header-notification-scroll position-relative"
                            style="max-height: calc(100vh - 215px)">
                            <p class="text-span">Today</p>
                            <div class="card mb-2">
                                <div class="card-body">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0">
                                            <svg class="pc-icon text-primary">
                                                <use xlink:href="#custom-layer"></use>
                                            </svg>
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <span class="float-end text-sm text-muted">2 min ago</span>
                                            <h5 class="text-body mb-2">UI/UX Design</h5>
                                            <p class="mb-0">Lorem Ipsum has been the industry's standard dummy text ever
                                                since the 1500s, when an unknown printer took a galley of
                                                type and scrambled it to make a type</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-2">
                                <div class="card-body">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0">
                                            <svg class="pc-icon text-primary">
                                                <use xlink:href="#custom-sms"></use>
                                            </svg>
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <span class="float-end text-sm text-muted">1 hour ago</span>
                                            <h5 class="text-body mb-2">Message</h5>
                                            <p class="mb-0">Lorem Ipsum has been the industry's standard dummy text ever
                                                since the 1500.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="text-span">Yesterday</p>
                            <div class="card mb-2">
                                <div class="card-body">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0">
                                            <svg class="pc-icon text-primary">
                                                <use xlink:href="#custom-document-text"></use>
                                            </svg>
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <span class="float-end text-sm text-muted">2 hour ago</span>
                                            <h5 class="text-body mb-2">Forms</h5>
                                            <p class="mb-0">Lorem Ipsum has been the industry's standard dummy text ever
                                                since the 1500s, when an unknown printer took a galley of
                                                type and scrambled it to make a type</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-2">
                                <div class="card-body">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0">
                                            <svg class="pc-icon text-primary">
                                                <use xlink:href="#custom-user-bold"></use>
                                            </svg>
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <span class="float-end text-sm text-muted">12 hour ago</span>
                                            <h5 class="text-body mb-2">Challenge invitation</h5>
                                            <p class="mb-2"><span class="text-dark">Jonny aber</span> invites to join
                                                the challenge</p>
                                            <button class="btn btn-sm btn-outline-secondary me-2">Decline</button>
                                            <button class="btn btn-sm btn-primary">Accept</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-2">
                                <div class="card-body">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0">
                                            <svg class="pc-icon text-primary">
                                                <use xlink:href="#custom-security-safe"></use>
                                            </svg>
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <span class="float-end text-sm text-muted">5 hour ago</span>
                                            <h5 class="text-body mb-2">Security</h5>
                                            <p class="mb-0">Lorem Ipsum has been the industry's standard dummy text ever
                                                since the 1500s, when an unknown printer took a galley of
                                                type and scrambled it to make a type</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center py-2">
                            <a href="javascript:void(0)" class="link-danger">Clear all Notifications</a>
                        </div>
                    </div>
                </li> -->
                <li class="dropdown pc-h-item header-user-profile">
                    <a class="pc-head-link dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown"
                        href="javascript:void(0)" role="button" aria-haspopup="false" data-bs-auto-close="outside"
                        aria-expanded="false">
                        <img src="assets/img/user.png" alt="user-image" class="user-avtar" />
                    </a>
                    <div class="dropdown-menu dropdown-user-profile dropdown-menu-end pc-h-dropdown">
                        <div class="dropdown-header d-flex align-items-center justify-content-between">
                            <h5 class="m-0">Profile</h5>
                        </div>
                        <div class="dropdown-body">
                            <div class="profile-notification-scroll position-relative"
                                style="max-height: calc(100vh - 225px)">
                                <div class="d-flex mb-1">
                                    <div class="flex-shrink-0">
                                        <img src="assets/img/user.png" alt="user-image" class="user-avtar wid-35" />
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="mb-1">{{token?.name}}</h6>
                                        <span>{{token?.email}}</span>
                                    </div>
                                </div>
                                <hr class="border-secondary border-opacity-50" />

                                @if(token?.permission?.multipleYard && yardArr && yardArr.length > 0)
                                {
                                    <select class="form-control" [(ngModel)]="selectedYard" (change)="yardChange($event)" aria-label="Default Yard">
                                       @for (item of token?.yards; track $index) 
                                       {
                                        <option value="{{item.id}}">{{item.name}}</option>
                                       }
                                    </select>

                                    <hr class="border-secondary border-opacity-50" />
                                }

                                <p class="text-span">Manage</p>
                                @if(token?.permission?.isViewSetting)
                                {
                                <a href="javascript:void(0)" routerLink="/setting" class="dropdown-item">
                                    <span>
                                        <svg class="pc-icon text-muted me-2">
                                            <use xlink:href="#custom-setting-outline"></use>
                                        </svg>
                                        <span>Settings</span>
                                    </span>
                                </a>
                                }


                                <!-- <a href="javascript:void(0)" class="dropdown-item">
                                    <span>
                                        <svg class="pc-icon text-muted me-2">
                                            <use xlink:href="#custom-lock-outline"></use>
                                        </svg>
                                        <span>Change Password</span>
                                    </span>
                                </a> -->

                                <a href="javascript:void(0)" (click)="logout()" class="dropdown-item ">
                                    <span>
                                        <i class="ti ti-power"></i>
                                        <span>Logout</span>
                                    </span>
                                </a>

                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</header>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasLDB2">

    <div class="offcanvas-header border pb-1">
        <div>
            <h5 id="offcanvasRightLabel text-center ">
                <span class="text-primary">LDB Container Tracking -
                    {{ldbData?.eximContainerTrail?.cntrDetail?.cntrno}}</span>
            </h5>
        </div>
    </div>

    <div class="offcanvas-body pt-0">
        <hr class="my-2 border border-secondary-subtle" />

        <div class="scroll-block">
            <div class="row">
                <div class="col-md-12">
                    <ul class="cbp_tmtimeline">
                        <li *ngFor="let item of ldbData?.eximContainerTrail?.trackLog; let i=index">

                            <div class="cbp_tmicon bg-primary">
                                <i class="ti ti-arrow-down" [ngClass]="{ 'ti ti-arrow-down': i != 0}"></i>
                            </div>

                            <div class="diff">
                                <small class="text-primary"> {{item.difference }} </small>
                            </div>


                            <div class="cbp_tmlabel empty p-3">
                                <span class="font-weight-bold">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <h6 class="mb-0">
                                                {{item.eventname}}

                                                @if (item.transportmode == 'VESSEL')
                                                {
                                                <small class="text-muted">via {{item.transportmode}} </small>
                                                }

                                                @if (item.latitude && item.longitude)
                                                {
                                                <span (click)="goToGoogleMaps(item.latitude,item.longitude)"
                                                    class="cursor-pointer wrapper">
                                                    <i class="ti ti-map-pin text-primary"></i>
                                                    <span class="tooltip text-light" style="width: 125px;">
                                                        <div>
                                                            View Google Maps
                                                        </div>
                                                    </span>
                                                </span>
                                                }

                                            </h6>
                                        </div>
                                    </div>

                                    <div>
                                        <small class="text-muted">{{item.currentlocation || item.orgname }}</small>
                                    </div>

                                    <!-- <div>
                                        <h6 class="mb-0">
                                            <small>{{item.transportmode}} </small>
                                        </h6>
                                    </div> -->

                                </span>

                                <span>
                                    <small class="text-muted">
                                        {{item.timeinms | date : 'medium'}}
                                    </small>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>