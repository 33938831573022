import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler,
    HttpRequest, HttpResponse
} from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';


@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
    constructor( public service: AuthService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
    {

        // if (req.url.includes('login')) 
        // {
        //     const re = this.encryptUsingAES256(req.body);
            
        //     const clonedRequest = req.clone({ body:null, setHeaders: { resource: re } }); // if plaintext is sent instead of json
        //     // const clonedRequest = req.clone({ body: {data : re} }); // if json is sent instead of plaintext
            
        //     return next.handle(clonedRequest).map((event: HttpEvent<any>) => 
        //     {
        //         if (event instanceof HttpResponse) 
        //         {
        //             if (event.url.includes('login')) 
        //             {
        //                 const re = this.decryptUsingAES256(event.body);
        //                 return event.clone({ body: JSON.parse(re) });
        //             }
        //         }
    
        //         return event;
        //     })
        //     .do((httpEvent: HttpEvent<any>) => this.logResponse(httpEvent));
        // }

        return next.handle(req).pipe(
            tap((httpEvent: HttpEvent<any>) => this.logResponse(httpEvent))
          );
    }

    private logResponse(httpEvent: HttpEvent<any>): void 
    {
        if (httpEvent instanceof HttpResponse)
        {
            // console.log(httpEvent.body.message);
            if(httpEvent && httpEvent.body && httpEvent.body.message && httpEvent.body.message.includes("Session Expired"))
            {
                this.service.error({_body: "Unauthorized", status: 401});
            }
        }
    }

    encryptUsingAES256(payload) {
        let _key = CryptoJS.enc.Utf8.parse(environment.encDensKey);
        let _iv = CryptoJS.enc.Utf8.parse(environment.encDensKey);
        let encrypted = CryptoJS.AES.encrypt(JSON.stringify(payload), _key,
        {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }

    decryptUsingAES256(encrypted) {
        let _key = CryptoJS.enc.Utf8.parse(environment.encDensKey);
        let _iv = CryptoJS.enc.Utf8.parse(environment.encDensKey);

        return CryptoJS.AES.decrypt(encrypted, _key,
        {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
    }
}
