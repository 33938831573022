import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration, withEventReplay } from '@angular/platform-browser';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { FormsModule } from '@angular/forms';
// import { RightbarComponent } from './shared/rightbar/rightbar.component';
import { PagerService, AuthService } from '../services/index'; 
import { HttpClientModule } from '@angular/common/http';
import { SocketService } from 'src/services/socket.service';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GtagModule } from 'angular-gtag';
import { LoggingInterceptor } from 'src/services/logging-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@NgModule({
  declarations: [
    AppComponent,
    // RightbarComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule, FormsModule,
    // GtagModule.forRoot({ trackingId:'G-WKR25Z6JKN', trackPageviews: true }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },AuthService , SocketService , PagerService, provideClientHydration(withEventReplay())],
  bootstrap: [AppComponent]
})
export class AppModule { }
