import { Component } from '@angular/core';
import { SidebarLayoutComponent } from './sidebar-layout/sidebar-layout.component';
import { HeaderLayoutComponent } from './header-layout/header-layout.component';
import { ContentLayoutComponent } from './content-layout/content-layout.component';
import { AuthLayoutComponent } from './auth-layout/login/auth-layout.component';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StorageService } from 'src/services';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-layout',
  standalone: true,
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  imports: [CommonModule, SidebarLayoutComponent, HeaderLayoutComponent, ContentLayoutComponent, AuthLayoutComponent]
})
export class LayoutComponent {
  currentPath: string;
  constructor(private route: ActivatedRoute, private storage: StorageService) {
    this.currentPath = this.route.snapshot.url.map(segment => segment.path).join('/');
  }
}
