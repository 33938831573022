import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthLayoutComponent } from './layout/auth-layout/login/auth-layout.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NomenclatureComponent } from './modules/lane/nomenclature/nomenclature.component';
// import { MapComponent } from './modules/map/map/map.component';
const routes: Routes =
  [
    // { path: '', pathMatch: 'full', redirectTo: 'login' },
    { path: 'login', component: AuthLayoutComponent },
    {
      path: '',
      // canActivate: [NoAuthGuard],
      // canActivateChild: [NoAuthGuard],
      component: AuthLayoutComponent,
      children: [
        // {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
        // {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
        // {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
        // {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
        // {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')}
      ]
    },
    { path: 'dashboard', component: LayoutComponent, loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), },
    { path: 'map', component: LayoutComponent, loadChildren: () => import('./modules/map/map.module').then(m => m.MapModule), },
    { path: 'analytics', component: LayoutComponent, loadChildren: () => import('./modules/analytics/analytics.module').then(m => m.AnalyticsModule), },
    { path: 'container', component: LayoutComponent, loadChildren: () => import('./modules/container/container.module').then(m => m.ContainerModule) },
    { path: 'user', component: LayoutComponent, loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule) },
    { path: 'gates', component: LayoutComponent, loadChildren: () => import('./modules/gates/gates.module').then(m => m.GatesModule) },
    { path: 'jobs', component: LayoutComponent, loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule) },
    { path: 'lane', component: LayoutComponent, loadChildren: () => import('./modules/lane/lane.module').then(m => m.LaneModule) },
    { path: 'rakeops', component: LayoutComponent, loadChildren: () => import('./modules/rakeops/rakeops.module').then(m => m.RakeopsModule) },
    { path: 'reports', component: LayoutComponent, loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule) },
    { path: 'resources', component: LayoutComponent, loadChildren: () => import('./modules/resources/resources.module').then(m => m.ResourcesModule) },
    { path: 'monitoring', component: LayoutComponent, loadChildren: () => import('./modules/monitoring/monitoring.module').then(m => m.MonitoringModule) },
    { path: 'taginventory', component: LayoutComponent, loadChildren: () => import('./modules/taginventory/taginventory.module').then(m => m.TaginventoryModule) },
    { path: 'asset', component: LayoutComponent, loadChildren: () => import('./modules/asset/asset.module').then(m => m.AssetModule) },
    { path: 'equipment', component: LayoutComponent, loadChildren: () => import('./modules/equipment/equipment.module').then(m => m.EquipmentModule) },
    { path: 'setting' ,component: LayoutComponent, loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule) },
    { path: 'miscellaneous' ,component: LayoutComponent, loadChildren: () => import('./modules/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule) },
    { path: 'extra-map' ,component: LayoutComponent, loadChildren: () => import('./modules/extra-map/extra-map.module').then(m => m.ExtraMapModule) },
    { path: 'trackmap', loadChildren: () => import('./modules/trackmap/trackmap.module').then(m => m.TrackmapModule), data: { title: 'Track View' } },

    { path: 'dynamic-keys', loadChildren: () => import('./modules/dynamic-keys/dynamic-keys.module').then(m => m.DynamicKeysModule) },
    { path: '**', component: AuthLayoutComponent },
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
